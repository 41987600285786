import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios';

function OrderStats () {

    const [orders, setOrders] = useState([]);


    useEffect(() => {
      getorders();
    }, []);
  

    const getorders = async () => {
      const response = await axios.get('https://testwp.zonepage.gr/animal_api/get_orders.php');
      setOrders(response.data);
  }
      
  const totalOver = () =>
  orders.filter(({total}) => total >= 30).reduce(
    (sum, order) => sum + parseInt(order.total),
    0
  )




     return (
    
      
          <div class="products-list col-md-6">

<div class="card">
        <div class="card-body">
        <h5 class="card-title">Πωλήσεις Άνω των 30€</h5>
        <p class="card-text"><small class="text-muted">{totalOver()} €</small></p>

        </div>
        </div>
         
        </div>
           
      
        );

}


export default OrderStats;