import React from "react";
import EksodaMonthly from "./EksodaMonthly";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import * as moment from 'moment'
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import axios from 'axios';
import Table from 'react-bootstrap/Table'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement } from 'chart.js';

import { Pie } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css'
import { values } from "sequelize/dist/lib/operators";
import { Line } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);



function About() {

  const api = new WooCommerceRestApi({
    url: "https://animal.gr",
    consumerKey: "ck_e10787100bf4f37088f0f3cfb7275f1bed291359",
    consumerSecret: "cs_579142154b4bcf51fac6e687232006c59dd08989",
    version: "wc/v3",
    wp_api: true,
    query_string_auth : true
  
  });

  const [EsodaMonth, setEsodaMonth] = useState([]);
  const [EksodaMonth, setEksodaMonth] = useState([]);
  const [orders, setOrders] = useState([]);
  const [payment_method_data, setPayment_method_data] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [sales, setSales] = useState([]);
  const [sales_dates, setSalesdates] = useState([]);


  useEffect(() => {
    getEsodaMonth();
    getEksodaMonth();
    getorders();
    fetchSales();
  }, []);


const loadagain = () => {
  getEsodaMonth();
  getEksodaMonth();
  getorders();
  fetchSales();

  }



 let Dates_orders = [];

  let fetchSales = () => {
    api
      .get("reports/sales", {
        date_min: moment(startDate).startOf('month').format('YYYY-MM-DD'),
        date_max:moment(startDate).endOf('month').format('YYYY-MM-DD')
      })
      .then((response) => {
        if (response.status === 200) {
          setSales(response.data[0].totals);
          console.log(response.data[0].totals);
          setSalesdates(Object.keys(response.data[0].totals));

        }
      })
      .catch((error) => {});
  };

  const getorders = async () => {
    const NewDate = moment(startDate).format('YYYY-MM-DD');
    console.log( moment(startDate).format('YYYY-MM-DD'));
    const response = await axios.post('https://testwp.zonepage.gr/animal_api/get_orders_monthly.php',  {
      date: NewDate}).then((response) => {
    setOrders(response.data);
    const payment_method_count = response.data.reduce((catsSoFar, { payment_method, total }) => {
      if (!catsSoFar[payment_method]) catsSoFar[payment_method] = [];
      catsSoFar[payment_method].push(total);
      return catsSoFar;
    }, {});

    
    setPayment_method_data([payment_method_count.cod.length, payment_method_count.eurobank_gateway.length, payment_method_count.paypal.length] )
 
    console.log(payment_method_count.cod.length);
  }, (error) => {
    console.log(error);
  });

}

const payment_shipping = () => {
return orders.filter(({shipping_total}) => shipping_total > 0).length;

}
const free_payment_shipping = orders.length - payment_shipping();



  const getEsodaMonth = async () => {
    const NewDate = moment(startDate).format('YYYY-MM-DD');
    console.log( moment(startDate).format('YYYY-MM-DD'));
   
    const response = await axios.post('https://testwp.zonepage.gr/animal_api/get_esoda_monthly.php',  {
      date: NewDate}).then((response) => {
        console.log(response);
    setEsodaMonth(response.data);
  }, (error) => {
    console.log(error);
  });
    
}

const getEksodaMonth = async () => {
  const NewDate = moment(startDate).format('YYYY-MM-DD');
  console.log( moment(startDate).format('YYYY-MM-DD'));
 
 
 
  const response = await axios.post('https://testwp.zonepage.gr/animal_api/get_eksoda_monthly.php',  {
    date: NewDate}).then((response) => {
      console.log(response);
      setEksodaMonth(response.data);
    }, (error) => {
      console.log(error);
    });

   
 
}

const totaleksoda = () =>
EksodaMonth.reduce(
  (sum, EksodoMonth) => sum + parseInt(EksodoMonth.cost),
  0
)

let labels_data = [];
let data_data = [];

{EksodaMonth.map((EksodοMonth, index) => {
  labels_data.push(EksodοMonth.label);
  data_data.push(EksodοMonth.cost);


})}




const options = {
  responsive: true,
  plugins: {

    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

let data_data_sales = [];
let data_data_orders = [];

Object.keys(sales).forEach(function(key) {
  data_data_sales.push(sales[key].sales);
  data_data_orders.push(sales[key].orders);
});

console.log(sales_dates)



 console.log(data_data_sales)
const data_orders = {
  labels : sales_dates,
  datasets: [
    {
      label: 'Έσοδα ανα ημέρα',
      data: data_data_sales,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  
  
  ],
};

 const data = {
  labels: labels_data,
  datasets: [
    {
      label: '# of Votes',
      data: data_data,
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

const data_payment_methods = {
  labels: ['Αντικαταβολή', 'Καρτα', 'Paypal'],
  datasets: [
    {
      label: '# of Votes',
      data:  payment_method_data,
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(40, 167, 69, 1)',
        'rgba(167, 40, 40, 1)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(40, 167, 69, 1)',
        'rgba(167, 40, 40, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


const data_free_shipping = {
  labels: ['Πληρωμένα Μεταφορικά', 'Δωρεάν Μεταφορικά'],
  datasets: [
    {
      label: '# of Votes',
      data:  [payment_shipping(), free_payment_shipping],
      backgroundColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(40, 167, 69, 1)',
        'rgba(167, 40, 40, 1)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(40, 167, 69, 1)',
        'rgba(167, 40, 40, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


  return (
    <div className="about">
      <div class="datepicker row my-5">
      <div class="col-md-12">
        <h2>Επιλέξτε Ημερομηνία</h2>
            <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      onCalendarClose={loadagain}
      dateFormat="yyyy-MM"
      showMonthYearPicker
      showFullMonthYearPicker
    />
     </div>
    </div>
      <div class="container">

     
      <div class="row  my-5">
        <div class="col-md-8">
      {EsodaMonth.map((EsodoMonth) => {
    return (

        <div class="row  my-5">
      <div class="products-list col-lg-4">
        <div class="card">
        <div class="card-body">
        <h5 class="card-title">Έσοδα</h5>
        <p class="card-text"><medium class="font-weight-bold">Ex(ΦΠΑ 24%) {Number(EsodoMonth.total/1.24).toFixed(2)} €</medium></p>
        <p class="card-text"><small class="text-muted">In(ΦΠΑ 24%) {Number(EsodoMonth.total).toFixed(2)} €</small></p>

        </div>
        </div>
       


        </div>

        <div class=" products-list col-lg-4">
        <div class="card">
        <div class="card-body">
        <h5 class="card-title">Profit</h5>
       
        <p class="card-text"><medium class="font-weight-bold">Ex(ΦΠΑ 24%) {Number(EsodoMonth.total_profit/1.24).toFixed(2)} €</medium></p>
        <p class="card-text"><small class="text-muted">In(ΦΠΑ 24%) {Number(EsodoMonth.total_profit).toFixed(2)} €</small></p>

        </div>
        </div>
            </div>


            <div class=" products-list col-lg-4">
        <div class="card">
        <div class="card-body">
        <h5 class="card-title">Margin</h5>
       
        <p class="card-text"><big class="font-weight-bold text-success">{Number(((EsodoMonth.total_profit/1.24)/(EsodoMonth.total/1.24))*100).toFixed(2)} %</big></p>
       

        </div>
        </div>
            </div>


            <div class=" products-list col-lg-4">
        <div class="card">
        <div class="card-body">
        <h5 class="card-title">Έσοδα Μεταφορικών</h5>
       
        <p class="card-text"><small class="text-muted">Ex(ΦΠΑ 24%) {Number(EsodoMonth.total_shipping_total/1.24).toFixed(2)} €</small></p>
        <p class="card-text"><small class="text-muted">In(ΦΠΑ 24%) {Number(EsodoMonth.total_shipping_total).toFixed(2)} €</small></p>

        </div>
        </div>
            </div>


            <div class=" products-list col-lg-4">
        <div class="card">
        <div class="card-body">
        <h5 class="card-title">Έσοδα Αντικαταβολών</h5>
       
        <p class="card-text"><small class="text-muted">Ex(ΦΠΑ 24%) {Number(EsodoMonth.cod_total/1.24).toFixed(2)} €</small></p>
        <p class="card-text"><small class="text-muted">In(ΦΠΑ 24%) {Number(EsodoMonth.cod_total).toFixed(2)} €</small></p>

        </div>
        </div>
            </div>


            <div class=" products-list col-lg-4">
        <div class="card">
        <div class="card-body">
        <h5 class="card-title">Καθαρό Κέρδος</h5>
        <p class="card-text"><small class="text-muted"> {Number(EsodoMonth.total_profit/1.24  - totaleksoda()).toFixed(2)} €</small></p>

        </div>
        </div>
            </div>

            

            </div>

            )
      })}
</div>
<div class="col-md-4">
<Pie data={data_payment_methods} />


</div>
</div>

<div class="row  my-5">
<div class="col-lg-8">
<Line options={options} data={data_orders} />
  </div>
  <div class="col-lg-4">
  <Pie data ={data_free_shipping} />
  </div>

  </div>
     
      <div class="row  my-5">
      <div class="col-lg-8">
    <Table striped bordered hover>
    <thead>
      <tr>
        <th>Έξοδα</th>
        <th>Κόστος</th>
       
        
      </tr>
    </thead>
    <tbody>
      {EksodaMonth.map((EksodοMonth, index) => {
        return (
          <tr key={index}>
            <td>{EksodοMonth.label}</td>
            <td>{EksodοMonth.cost} €</td>
          
          
          </tr>
        );
      })}
    </tbody>
      <tfoot>
          <tr>
              <td>Σύνολο</td>
              <td>{totaleksoda()} €</td>
          </tr>
      </tfoot>
  </Table>
  </div>

  <div class="col-md-4">
  <Pie data={data} />
 
  </div>
  
  </div>
     
       

      </div>
    </div>
  );
}

export default About;