import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios';
import Table from 'react-bootstrap/Table'

function EksodaMonthly () {

    const [EksodaMonth, setEksodaMonth] = useState([]);


    useEffect(() => {
      getEksodaMonth();
    }, []);
  

    const getEksodaMonth = async () => {
      const response = await axios.get('https://testwp.zonepage.gr/animal_api/get_eksoda_monthly.php');
      setEksodaMonth(response.data);
  }
      

  const totaleksoda = () =>
  EksodaMonth.reduce(
    (sum, EksodoMonth) => sum + parseInt(EksodoMonth.cost),
    0
  )



     return (
    
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>Έξοδα</th>
            <th>Κόστος</th>
           
            
          </tr>
        </thead>
        <tbody>
          {EksodaMonth.map((EksodοMonth, index) => {
            return (
              <tr key={index}>
                <td>{EksodοMonth.label}</td>
                <td>{EksodοMonth.cost} €</td>
              
              
              </tr>
            );
          })}
        </tbody>
          <tfoot>
              <tr>
                  <td>Σύνολο</td>
                  <td>{totaleksoda()}</td>
              </tr>
          </tfoot>
      </Table>
     
      
        );

}


export default EksodaMonthly;