import { useEffect, useState } from "react";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import React from "react";
import '../App.css';
import { Table } from "react-bootstrap";


const api = new WooCommerceRestApi({
  url: "https://animal.gr",
  consumerKey: "ck_e10787100bf4f37088f0f3cfb7275f1bed291359",
  consumerSecret: "cs_579142154b4bcf51fac6e687232006c59dd08989",
  version: "wc/v3",
  wp_api: true,
  query_string_auth : true

});

function Orders() {  const [orders, setOrders] = useState([]);

    useEffect(() => {
      fetchOrders();
    }, []);
  
    let fetchOrders = () => {
      api
        .get("orders", {
          per_page: 100,
          status: 'processing'
        })
        .then((response) => {
          if (response.status === 200) {
            setOrders(response.data);
          }
        })
        .catch((error) => {});
    };
  
    return (
      <div className="App">
         
          <div>{orders.length}</div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Date</th>
              <th>Amount</th>
              <th>Name</th>
              <th>Email</th>
              <th>Adress</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => {
              return (
                <tr key={index}>
                  <td>{order.id}</td>
                  <td>{order.date}</td>
                  <td>{order.total}</td>
                  <td>{order.billing && order.billing.first_name}</td>
                  <td>{order.billing && order.billing.email}</td>
                  <td>{order.billing && order.billing.address_1}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }


export default Orders;