import React from "react";
import { useEffect, useState } from "react";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import Moment from 'react-moment';
import Table from 'react-bootstrap/Table'
import axios from 'axios';
import * as moment from 'moment'
import DigitalStats from "./DigitalStats";
import OrderStats from "./OrdersStats";

const api = new WooCommerceRestApi({
  url: "https://animal.gr",
  consumerKey: "ck_e10787100bf4f37088f0f3cfb7275f1bed291359",
  consumerSecret: "cs_579142154b4bcf51fac6e687232006c59dd08989",
  version: "wc/v3",
  wp_api: true,
  query_string_auth : true

});



 function Home() {





 var d = new Date();
 const yesterday = d.setDate(d.getDate() - 1);
 const NewDate = moment(moment().add(-1, 'days')).format('YYYY-MM-DD')






  const [sales, setSales] = useState([]);
  
  const [topproduct, setTop] = useState([]);
  
  useEffect(() => {
    fetchSales();
    fetchTopPro();
  }, []);

  let fetchSales = () => {
    api
      .get("reports/sales", {
        date_min: NewDate,
        date_max: NewDate
      })
      .then((response) => {
        if (response.status === 200) {
          setSales(response.data);
          console.log(response.data);
        }
      })
      .catch((error) => {});
  };

  let fetchTopPro = () => {
    api
      .get("reports/top_sellers", {
        date_min: NewDate,
        date_max: NewDate
      })
      .then((response) => {
        if (response.status === 200) {
          setTop(response.data);
          console.log(response.data);
        }
      })
      .catch((error) => {});
  };

  return (

    
    <div className="home">
      <div class="container">
      <div class="row  align-items-top">
        <div class="col-md-8">
       
      <div class="row  align-items-center">
      <div class="col-lg-12 my-5">
       <h3> {NewDate} </h3>
        </div>
      {sales.map((sale) => {
        return (
      <div class="col-lg-12 my-5">
        <div class="row">
      <div class="products-list col-lg-6">
        <div class="card">
        <div class="card-body">
        <h5 class="card-title">Έσοδα</h5>
        <p class="card-text"><small class="text-muted"> {sale.total_sales} €</small></p>

        </div>
        </div>
       


        </div>

        <div class=" products-list col-lg-6">
        <div class="card">
        <div class="card-body">
        <h5 class="card-title">Παραγγελίες</h5>
        <p class="card-text"><small class="text-muted">  {sale.total_orders}</small></p>

        </div>
        </div>
            </div>

        <div class=" products-list col-lg-6">

        <div class="card">
        <div class="card-body">
        <h5 class="card-title">Χωρίς Μεταφορικά</h5>
        <p class="card-text"><small class="text-muted"> {sale.net_sales} €</small></p>

        </div>
        </div>
       
    
       </div>

       <div class=" products-list col-lg-6">

       <div class="card">
        <div class="card-body">
        <h5 class="card-title">Μεταφορικά</h5>
        <p class="card-text"><small class="text-muted"> {sale.total_shipping} €</small></p>

        </div>
        </div>
       



       </div>
        
        </div>
        </div>
        )
      })}

<OrderStats />
</div>
<DigitalStats />
</div>

<div class="col-md-4 my-5">

<h3> Tops Sellers </h3>

<Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Προϊόν</th>
              <th>Ποσότητα</th>
              
            </tr>
          </thead>
          <tbody>
            {topproduct.map((product, index) => {
              return (
                <tr key={index}>
                  <td>{index}</td>
                  <td>{product.name}</td>
                  <td>{product.quantity}</td>
                
                </tr>
              );
            })}
          </tbody>
        </Table>

  
</div>

</div>





      
      </div>
    </div>
  );


}


export default Home;