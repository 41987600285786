import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios';



function DigitalStats () {

    const [posts, setPosts] = useState([]);


    useEffect(() => {
      getstats();
    }, []);
  

    const getstats = async () => {
      const response = await axios.get('https://testwp.zonepage.gr/animal_api/get_eksoda.php');
      setPosts(response.data);
  }
      
  const totalPrice = () =>
  posts.reduce(
    (sum, post) => sum + parseInt(post.amount),
    0
  )

  const totalSales = () =>
  posts.reduce(
    (sum, post) => sum + parseInt(post.sales),
    0
  )



     return (
    <div class="row align-items-center my-5">
      <div class="col-md-12 my-5">
        <h4>Πωλήσεις και κόστος Διαφημίσεων</h4>
        </div>
        {posts.map((post, index) => (

       



        <div class="products-list col-md-3">
          { post.labelid === "1" &&
          <img class="icon-70" src={process.env.PUBLIC_URL + '/googleads.png'} alt="google" /> 
          
        }

        { post.labelid === "2" &&
                  <img class="icon-70" src={process.env.PUBLIC_URL + '/1260673.png'} alt="facebook" /> 
                  
        }
        { post.labelid === "3" &&
                  <img class="icon-70" src={process.env.PUBLIC_URL + '/skroutz.png'} alt="skroutz"/> 
                  
        }
           
           <span>Κόστος {post.amount} €</span>
           <span>Πωλήσεις {post.sales}</span>

         </div>

        ))}
          <div class="products-list col-md-3">
          <img class="icon-70" src={process.env.PUBLIC_URL + '/adspend.png'} alt="facebook" /> 
          <span>Συνολικό Κόστος  {totalPrice()} €</span>
          <span>Συνολικές Πωλήσεις {totalSales()}</span>
           
          </div>
     </div>
        );

}


export default DigitalStats;