import { useEffect, useState } from "react";
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import React from "react";
import '../App.css';



const api = new WooCommerceRestApi({
  url: "https://animal.gr",
  consumerKey: "ck_e10787100bf4f37088f0f3cfb7275f1bed291359",
  consumerSecret: "cs_579142154b4bcf51fac6e687232006c59dd08989",
  version: "wc/v3",
  wp_api: true,
  query_string_auth : true

});

function Products() {
    const [products, setProducts] = useState([]);
  
    useEffect(() => {
      fetchOrders();
    }, []);
  
    let fetchOrders = () => {
      api
        .get("products", {
          per_page: 12,
        })
        .then((response) => {
          if (response.status === 200) {
            setProducts(response.data);
          }
        })
        .catch((error) => {});
    };

    return (

<div className="App">
<div class="container">
        <div class="row align-items-center my-5">
 
    
          {products.map((product, index) => {
            let [first] = Object.keys(product.images)
            const image  = product.images[Object.keys(product.images)[0]].src
              
            return (


              <div class="col-md-3  products-list"  key={index}>
              <img src={image} class="product-image" alt="test"></img>
              <span class="product-name">{product.name}</span>
              <span class="product-price">{product.price} €</span>
          </div>

            
            );
          })}
        
    </div>
    </div>
    </div>
  );
}

export default Products;